export const apps = {
    cpc: {
      ios: 'https://itunes.apple.com/pt/app/cpc-anotado/id1005084892?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.weareredlight.cpccomparado&hl=pt_BR'
    },
    fam: {
      ios: 'https://itunes.apple.com/pt/app/leis-de-familia/id1441976486?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.weareredlight.leisdafamiliaanotado'
    },
    lms: {
      ios: 'https://itunes.apple.com/pt/app/mandado-de-seguranca-anotado/id1435147866?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.weareredlight.lmsanotado'
    }
}

