import React from 'react'
import c from 'classnames';
import Layout from 'components/layout';

// import SVGAnimation from 'components/atoms/SVGAnimation';
// import { AppStore, PlayStore } from 'components/atoms/DownloadButtons';

// import * as bookmarksAni from 'assets/animations/bookmarks.json';
// import * as comparacaoAni from 'assets/animations/comparacao.json';
// import * as contaAni from 'assets/animations/conta.json';
// import * as fontSizeAni from 'assets/animations/font_size.json';
// import * as offlineAni from 'assets/animations/offline.json';
// import * as pesquisaAni from 'assets/animations/pesquisa.json';
// import * as syncAni from 'assets/animations/sync.json';
// import * as anotacoesAni from 'assets/animations/anotacoes.json';

import androidSrc from 'assets/images/android_white.svg';
import iosSrc from 'assets/images/ios_white.svg';
import cpcSrc from 'assets/images/cpc_logo.svg';
import lfSrc from 'assets/images/lf_logo.svg';
import cpcIphonesSrc from 'assets/images/cpc_iphones.png';
import lfIphonesSrc from 'assets/images/lf_iphones.png';

import wrappers from 'styles/_wrappers.module.scss';
import helpers from 'styles/_helpers.module.scss';
import styles from 'styles/pages/funcionalidades.module.scss';
import { apps } from 'constants.js'

const Produtos = ({ location }) => (
  <Layout location={location} title="Produtos">
    <div className={styles.app_cpc}>
      <div className={styles.apps_container}>
        <img src={cpcSrc} className={styles.app_logo} alt="" />
        <div>
          <h3>Código de Processo Cívil</h3>
          <p>Com o CPC Anotado poderá consultar o Novo Código de Processo Civil de 2015 e ainda comparar artigo por artigo com o Código de 1973 (e vice-versa), com anotações específicas em diferentes artigos.</p>
        </div>
      </div>
      <h2 className={helpers.mt24}>Baixe o aplicativo para : </h2>
      <div className={wrappers.flex_vam_center}>
        <a href={apps.cpc.android} className={c(helpers.mt24,styles.btn, helpers.mr8)}>
          <img src={androidSrc} className={helpers.mr8} alt="" />android
        </a>
        <a href={apps.cpc.ios} className={c(helpers.mt24,styles.btn, helpers.ml8)}>
          <img src={iosSrc} className={helpers.mr8} alt="" />ios
        </a>
      </div>
      <img src={cpcIphonesSrc} className={c(styles.cpc_iphones, helpers.mt24)} alt="" />
    </div>

    <div className={styles.app_lf}>
      <div className={styles.apps_container}>
        <img src={lfSrc} className={styles.app_logo} alt="" />
        <div>
          <h3>Leis de Família</h3>
          <p>Com o aplicativo Leis de Família do Anotado, terá acesso à Lei dos Alimentos, Lei dos Alimentos Gravídicos, Lei de Investigação de Parternidade, Lei Sobre Alienação Parental e Lei da Guarda Partilha. Todas as leis contam com artigos anotados pela Camilla de Araújo Cavalcanti.</p>
        </div>
      </div>
      <h2 className={helpers.mt24}>Baixe o aplicativo para : </h2>
      <div className={wrappers.flex_vam_center}>
        <a href={apps.fam.android} className={c(helpers.mt24,styles.btn, helpers.mr8)}>
          <img src={androidSrc} className={helpers.mr8} alt="" />android
        </a>
        <a href={apps.fam.ios} className={c(helpers.mt24,styles.btn, helpers.ml8)}>
          <img src={iosSrc} className={helpers.mr8} alt="" />ios
        </a>
      </div>
      <img src={lfIphonesSrc} className={c(styles.cpc_iphones, helpers.mt24)} alt="" />
    </div>
  </Layout>
);

export default Produtos;
